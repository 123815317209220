import type { BedSelectItem } from 'shared/types'
import { fillArrayNumber } from '../lib/fillArrayNumber';

const maxNumberOfBeds: number = 20

export const BED_COUNTS: BedSelectItem[] = [
    {
        label: 'Any',
        value: [0]
    },
    {
        label: '1',
        value: [1]
    },
    {
        label: '1+',
        value: fillArrayNumber(1, maxNumberOfBeds)
    },
    {
        label: '2',
        value: [2]
    },
    {
        label: '2+',
        value: fillArrayNumber(2, maxNumberOfBeds)
    },
    {
        label: '3',
        value: [3]
    },
    {
        label: '3+',
        value: fillArrayNumber(3, maxNumberOfBeds)
    },
    {
        label: '4',
        value: [4]
    },
    {
        label: '4+',
        value: fillArrayNumber(4, maxNumberOfBeds)
    },
    {
        label: '5',
        value: [5]
    },
    {
        label: '5+',
        value: fillArrayNumber(5, maxNumberOfBeds)
    },
    {
        label: '6',
        value: [6]
    },
    {
        label: '6+',
        value: fillArrayNumber(6, maxNumberOfBeds)
    },
    {
        label: '7',
        value: [7]
    },
    {
        label: '7+',
        value: fillArrayNumber(7, maxNumberOfBeds)
    },
    {
        label: '8',
        value: [8]
    },
    {
        label: '8+',
        value: fillArrayNumber(8, maxNumberOfBeds)
    },
];