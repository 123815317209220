import type { BedroomSelectItem } from 'shared/types'
import { fillArrayNumber } from '../lib/fillArrayNumber';

const maxNumberOfBedrooms: number = 20

export const BEDROOM_COUNTS: BedroomSelectItem[] = [
    {
        label: 'Any',
        value: [0]
    },
    {
        label: '1',
        value: [1]
    },
    {
        label: '1+',
        value: fillArrayNumber(1, maxNumberOfBedrooms)
    },
    {
        label: '2',
        value: [2]
    },
    {
        label: '2+',
        value: fillArrayNumber(2, maxNumberOfBedrooms)
    },
    {
        label: '3',
        value: [3]
    },
    {
        label: '3+',
        value: fillArrayNumber(3, maxNumberOfBedrooms)
    },
    {
        label: '4',
        value: [4]
    },
    {
        label: '4+',
        value: fillArrayNumber(4, maxNumberOfBedrooms)
    },
    {
        label: '5',
        value: [5]
    },
    {
        label: '5+',
        value: fillArrayNumber(5, maxNumberOfBedrooms)
    },
    {
        label: '6',
        value: [6]
    },
    {
        label: '6+',
        value: fillArrayNumber(6, maxNumberOfBedrooms)
    },
    {
        label: '7',
        value: [7]
    },
    {
        label: '7+',
        value: fillArrayNumber(7, maxNumberOfBedrooms)
    },
    {
        label: '8',
        value: [8]
    },
    {
        label: '8',
        value: fillArrayNumber(8, maxNumberOfBedrooms)
    },
];