import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { DateTime } from 'luxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { useNavigate } from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';
import {
    Box,
    FormGroup,
    FormControl,
    InputLabel,
    Button,
    TextField,
    Typography,
    Stack,
    MenuItem,
    Checkbox,
    FormLabel,
    Select,
    OutlinedInput
} from '@mui/material';
import { MuiChipsInput } from 'components/ChipsInput';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { createBlankMonitor, createBlankMonitorCreateViewResource, updateMonitorCreateFilter, updateMonitorFilter, updateMonitorUpdateFilter } from 'domain/monitor';
import { PET_COUNTS } from 'shared/constants';
import { BED_COUNTS } from 'shared/constants';
import { BEDROOM_COUNTS } from 'shared/constants';
import { Controller } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAppDispatch } from 'shared/hooks';
import { useAppSelector } from 'shared/hooks';
import { selectPlatforms } from 'store/platformsSlice';
import { readPlatforms } from 'store/platformsSlice';
import { DATE_PERIODS } from 'shared/constants';
import { useReadLocationTipsQuery } from 'services/location';
import * as z from 'zod';
import type { BedSelectItem, MonitorUpdateViewResource,  } from 'shared/types';
import type { BedroomSelectItem } from 'shared/types';
import type { DatePeriod } from 'shared/types';
import type { Monitor } from 'shared/types';
import type { MonitorCustomPeriod } from 'shared/types';
import type { MonitorSlidingPeriod } from 'shared/types';
import type { MonitorFilterItem } from 'shared/types';
import type { PetSelectItem } from 'shared/types';
import type { MonitorUpdateResource } from 'shared/types';
import type { Platform } from 'shared/types';

export type MonitorEditFormViewProps = {
    value?: MonitorUpdateViewResource,
    onChange?: Function,
    onSubmit?: Function,
    onBack?: Function,
};

type Period = {
    id: number,
    name: string
}

const FormSchema = z.object({
    title: z
        .string()
        .nonempty(),
    description: z
        .string(),
    enabled: z
        .boolean(),
    bedrooms: z
        .string()
        .nonempty(),
    beds: z
        .string()
        .nonempty(),
    locations: z
        .array(z.string())
        .nonempty(),
    collectNearByLocations: z
        .boolean()
}).passthrough();

export const MonitorEditFormView = (props: MonitorEditFormViewProps): JSX.Element => {
    const navigate = useNavigate();
    const [monitor, setMonitor] = useState<MonitorUpdateViewResource | undefined>(undefined);
    const [period, setPeriod] = useState<Period | undefined>(undefined);
    const {
        value = createBlankMonitorCreateViewResource(),
        onSubmit,
        onBack
    } = props;


    const {
        handleSubmit,
        formState: { errors, submitCount, isSubmitting },
        control,
        getValues,
        reset,
        trigger
    } = useForm<MonitorUpdateViewResource>({
        resolver: zodResolver(FormSchema),
        defaultValues: value
    });
    const dispatch = useAppDispatch();
    const platforms: Platform[] = useAppSelector(selectPlatforms);

    const {
        data: readLocationTipsData,
        isLoading: readLocationTipsDataIsLoading
    } = useReadLocationTipsQuery(undefined);

    

    const getPeriodFromMonitor = (monitor: MonitorUpdateViewResource) => {
        if (Array.isArray(monitor?.customPeriods) && monitor.customPeriods.length > 0) {
            return DATE_PERIODS[3]
        }

        if (Array.isArray(monitor?.slidingPeriods) && monitor.slidingPeriods.length > 0) {
            if (monitor.slidingPeriods[0].offset === 0) {
                return DATE_PERIODS[0]
            }

            if (monitor.slidingPeriods[0].offset === 1) {
                return DATE_PERIODS[1]
            }

            return DATE_PERIODS[1];
        }

        return DATE_PERIODS[1];
    }

    const submitFormHandler = (value: MonitorUpdateViewResource, event: any) => {
        event.preventDefault();

        if (typeof onSubmit === "function") {
            onSubmit({
                event: event,
                value: value
            });
        }
    }

    const onBackHandler = (event: any) => {
        if (typeof onBack === "function") {
            onBack({
                event: event
            });
        }

        navigate(-1);
    }

    const renderDaysAhead = () => {
        const renderInput = () => {
            return (<Controller
                name="daysAhead"
                control={control}
                render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState: { dirtyFields, touchedFields },
                }) => (
                    <FormControl required>
                        <TextField
                            label='Days ahead'
                            sx={{ mb: 2 }}
                            InputProps={{
                                inputProps: { min: 0 },
                            }}
                            type="number"
                            fullWidth
                            error={!!errors['daysAhead']}
                            helperText={errors['daysAhead'] ? errors['daysAhead'].message : ''}
                            value={value}
                            name={name}
                            onChange={
                                async (event) => {
                                    const newMonitor: MonitorUpdateViewResource = await recalculateMonitor(getValues(), name, event.target.value)
                                    updateMonitorDataOnForm(newMonitor);
                                    validateFields();
                                }
                            }
                        />
                    </FormControl>
                )}
            />)
        }

        return (
            (period?.id === 1000 && value !== undefined ? '' : renderInput())
        )
    }

    const renderPeriod = () => {
        const customPeriod = () => {
            return (
            <>
                <Controller
                    control={control}
                    name="customPeriods"
                    render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState: { dirtyFields },
                    }) => (
                        <>
                            <FormControl sx={{ width: 300, marginTop: '10px' }}>
                                <DatePicker
                                    label="From"
                                    value={value?.[0]?.startDateTimeUtc === undefined ? DateTime.now() : DateTime.fromISO(value?.[0]?.startDateTimeUtc)}
                                    onChange={
                                        async (value) => {
                                            const monitor = getValues();

                                            if (monitor?.customPeriods?.[0].endDateTimeUtc !== undefined) {
                                                const customPeriod: MonitorCustomPeriod = {
                                                    startDateTimeUtc: value === null ? '' : value.toUTC().toString(),
                                                    endDateTimeUtc: DateTime.fromISO(monitor.customPeriods[0].endDateTimeUtc).toUTC().toString()
                                                }

                                                const newMonitor: MonitorUpdateViewResource = await recalculateMonitor(monitor, 'customPeriods', [customPeriod])
                                                updateMonitorDataOnForm(newMonitor);
                                                validateFields();
                                            }

                              
                                        }
                                    }
                                />
                            </FormControl>
                            <FormControl sx={{ width: 300, marginTop: '10px' }}>
                                <DatePicker
                                    label="To"
                                    value={value?.[0]?.endDateTimeUtc === undefined ? DateTime.now() : DateTime.fromISO(value?.[0]?.endDateTimeUtc)}
                                    onChange={
                                        async (value) => {
                                            const monitor = getValues();

                                            if (monitor?.customPeriods?.[0].startDateTimeUtc !== undefined) {
                                                const customPeriod: MonitorCustomPeriod = {
                                                    startDateTimeUtc: DateTime.fromISO(monitor.customPeriods[0].startDateTimeUtc).toUTC().toString(),
                                                    endDateTimeUtc: value === null ? '' : value.toUTC().toString()
                                                }

                                                const newMonitor: MonitorUpdateViewResource = await recalculateMonitor(monitor, 'customPeriods', [customPeriod])
                                                updateMonitorDataOnForm(newMonitor);
                                                validateFields();
                                            }
                                        }
                                    }
                                />
                            </FormControl>
                        </>
                    )}
                />
            </>
            )
        }




        if (period?.id === 1000 && value !== undefined) {
            return (
                <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={'en-us'}>                 
                    {customPeriod()}
                </LocalizationProvider>
            )
        }
    }

    const recalculateMonitor = async (monitor: MonitorUpdateViewResource, name: string, value: any): Promise<MonitorUpdateViewResource> => {
        const monitorFilterItem: MonitorFilterItem = {
            name: name,
            value: value
        };

        const newMonitor: MonitorUpdateViewResource = updateMonitorUpdateFilter({ monitor: monitor, monitorFilterItem });
        return newMonitor;
    }

    const updateMonitorDataOnForm = (monitor: MonitorUpdateViewResource) => {
        reset(monitor, { keepDirtyValues: true, keepSubmitCount: true })
        setMonitor(monitor)
    }

    const validateFields = () => {
        if (submitCount > 0) {
            trigger();
        }
    }

    useEffect(() => {
        dispatch(readPlatforms());
    }, []);

    useEffect(() => {
        if (value === undefined) {
            return;
        }

        setMonitor(value);
        setPeriod(getPeriodFromMonitor(value))
        reset(value, { keepDirtyValues: true, keepSubmitCount: true })
    }, [value]);

    const render = () => {
        if (monitor === undefined || value === undefined) {
            return (
                <>

                </>
            )
        }

        return (
            <>
                <Box sx={{ display: 'Grid', rowGap: '20px' }}
                    component="form" onSubmit={handleSubmit(submitFormHandler)}
                >
         
                    <Typography variant="h4">Edit spy</Typography>
                    <Controller
                        name="title"
                        control={control}
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState: { dirtyFields },
                        }) => (
                            <TextField
                                sx={{ mb: 2 }}
                                label='Title *'
                                fullWidth
                                error={!!errors['title']}
                                helperText={errors['title'] ? errors['title'].message : ''}
                                value={value}
                                name={name}
                                onChange={
                                    async (event) => {
                                        const newMonitor: MonitorUpdateViewResource = await recalculateMonitor(getValues(), name, event.target.value)
                                        updateMonitorDataOnForm(newMonitor);
                                        validateFields();
                                    }
                                }
                            />
                        )}
                    />
                    <Controller
                        name="description"
                        control={control}
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState: { dirtyFields },
                        }) => (
                            <TextField
                                sx={{ mb: 2 }}
                                label='Description'
                                fullWidth
                                error={!!errors['description']}
                                helperText={errors['description'] ? errors['description'].message : ''}
                                value={value}
                                name={name}
                                onChange={
                                    async (event) => {
                                        const newMonitor: MonitorUpdateViewResource = await recalculateMonitor(getValues(), name, event.target.value)
                                        updateMonitorDataOnForm(newMonitor);
                                        validateFields();
                                    }
                                }
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="locations"
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState: { dirtyFields },
                        }) => (
                            <FormControl required>
                                <MuiChipsInput
                                    autoCompleteOptions={readLocationTipsData || []}
                                    helperText={errors['locations'] ? errors['locations'].message : ''}
                                    error={!!errors['locations']}
                                    clearInputOnBlur={true}
                                    value={value}
                                    name={name}
                                    label="Locations *"
                                    onChange={
                                        async (value) => {
                                            const newMonitor: MonitorUpdateViewResource = await recalculateMonitor(getValues(), name, value);
                                            updateMonitorDataOnForm(newMonitor);
                                            validateFields();
                                        }}
                                />
                            </FormControl>
                        )}
                    />
                    <Controller
                        control={control}
                        name="collectNearByLocations"
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState: { dirtyFields },
                        }) => (
                            <FormControl sx={{ my: 0 }} component="fieldset" variant="standard">
                                <FormGroup>
                                    <FormLabel>Collect Near By Locations</FormLabel>
                                    <div>
                                        <Checkbox
                                            name={name}
                                            checked={value}
                                            onChange={
                                                async (event) => {
                                                    const newMonitor: MonitorUpdateViewResource = await recalculateMonitor(getValues(), name, event.target.checked);
                                                    updateMonitorDataOnForm(newMonitor);
                                                    validateFields();
                                                }
                                            }
                                        />
                                    </div>
                                </FormGroup>
                            </FormControl>
                        )}
                    />
                    <Controller
                        control={control}
                        name="bedrooms"
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState: { dirtyFields },
                        }) => (
                            <FormControl required sx={{ width: 300, margin: '0 0 0 0' }}>
                                <InputLabel id="bedrooms-label">Bedrooms</InputLabel>
                                <Select
                                    labelId="bedrooms-label"
                                    id="bedrooms-selector"
                                    name={name}
                                    value={value}
                                    onChange={
                                        async (event) => {
                                            const newMonitor: MonitorUpdateViewResource = await recalculateMonitor(getValues(), name, event.target.value);
                                            updateMonitorDataOnForm(newMonitor);
                                            validateFields();
                                        }
                                    }
                                    input={<OutlinedInput label="Bedrooms" />}
                                >
                                    {BEDROOM_COUNTS.map((obj: BedroomSelectItem) => (
                                        <MenuItem key={obj.label} value={obj.label}>
                                            {obj.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    />

                    <Controller
                        control={control}
                        name="beds"
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState: { dirtyFields },
                        }) => (
                            <FormControl required sx={{ width: 300, margin: '0 0 0 0' }}>
                                <InputLabel id="beds-label">Beds</InputLabel>
                                <Select
                                    labelId="beds-label"
                                    id="beds-selector"
                                    name={name}
                                    value={value}
                                    onChange={
                                        async (event) => {
                                            const newMonitor: MonitorUpdateViewResource = await recalculateMonitor(getValues(), name, event.target.value);
                                            updateMonitorDataOnForm(newMonitor);
                                            validateFields();
                                        }
                                    }
                                    input={<OutlinedInput label="Beds" />}
                                >
                                    {BED_COUNTS.map((obj: BedSelectItem) => (
                                        <MenuItem key={obj.label} value={obj.label}>
                                            {obj.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    />

                    <Controller
                        control={control}
                        name="pets"
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState: { dirtyFields },
                        }) => (
                            <FormControl required sx={{ width: 300, margin: '0 0 0 0' }}>
                                <InputLabel id="pets-label">Pets</InputLabel>
                                <Select
                                    labelId="pets-label"
                                    id="pets-selector"
                                    name={name}
                                    value={value}
                                    onChange={
                                        async (event) => {
                                            const newMonitor: MonitorUpdateViewResource = await recalculateMonitor(getValues(), name, event.target.value);
                                            updateMonitorDataOnForm(newMonitor);
                                            validateFields();
                                        }
                                    }
                                    input={<OutlinedInput label="Pets" />}
                                >
                                    {PET_COUNTS.map((obj: PetSelectItem) => (
                                        <MenuItem key={obj.value} value={obj.value}>
                                            {obj.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    />

                    <Controller
                        control={control}
                        name="platformId"
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState: { dirtyFields },
                        }) => (
                            <FormControl sx={{ width: 300, margin: '20px 0' }}>
                                <InputLabel id="platforms-multiple-checkbox-label">Platform *</InputLabel>
                                <Select
                                    labelId="platforms-multiple-checkbox-label"
                                    id="platforms-multiple-checkbox"
                                    name={name}
                                    value={value}
                                    onChange={
                                        async (event) => {
                                            const newMonitor: MonitorUpdateViewResource = await recalculateMonitor(getValues(), name, event.target.value);
                                            updateMonitorDataOnForm(newMonitor);
                                            validateFields();
                                        }
                                    }
                                    input={<OutlinedInput label="Platforms" />}
                                >
                                    {platforms.map((obj: any) => (
                                        <MenuItem key={obj.id} value={obj.id}>
                                            {obj.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    />

                    <Controller
                        control={control}
                        name="period"
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState: { dirtyFields },
                        }) => (
                            <FormControl required sx={{ width: 300, margin: '0 0 0 0' }}>
                                <InputLabel id="period-checkbox-label">Period</InputLabel>
                                <Select
                                    labelId="period-checkbox-label"
                                    id="period-checkbox"
                                    name={name}
                                    value={period?.id.toString()}
                                    onChange={
                                        async (event) => {
                                            const foundPeriod: DatePeriod | undefined = DATE_PERIODS.find((period) => period.id === parseInt(event.target.value))

                                            if (foundPeriod !== undefined) {
                                                setPeriod(foundPeriod);

                                                if (foundPeriod?.value !== undefined) {
                                                    let newMonitor: MonitorUpdateViewResource = await recalculateMonitor(getValues(), 'daysAhead', 1);
                                                    newMonitor = await recalculateMonitor(newMonitor, 'customPeriods', []);

                                                    updateMonitorDataOnForm(newMonitor);
                                                    validateFields();
                                                    return;
                                                }

                                                const customPeriod: MonitorCustomPeriod = {
                                                    startDateTimeUtc: DateTime.now().plus({ days: 0 }).toUTC().toString(),
                                                    endDateTimeUtc: DateTime.now().plus({ days: 1 }).toUTC().toString()
                                                }


                                                let newMonitor: MonitorUpdateViewResource = await recalculateMonitor(getValues(), 'daysAhead', 0);
                                                newMonitor = await recalculateMonitor(newMonitor, 'customPeriods', [customPeriod]);
                                                newMonitor = await recalculateMonitor(newMonitor, 'slidingPeriods', []);
                                                updateMonitorDataOnForm(newMonitor);
                                                validateFields();


                                            }
                                        }
                                    }
                                    input={<OutlinedInput label="Period" />}
                                >
                                    {DATE_PERIODS
                                        .filter((datePeriod) => datePeriod.enabled === true)
                                        .map((obj: any) => (
                                            <MenuItem key={obj.id} value={obj.id}>
                                                {obj.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        )}
                    />

                    {renderDaysAhead()}
                    {renderPeriod()}

                    <Controller
                        name="priceForComparison"
                        control={control}
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState: { dirtyFields },
                        }) => (
                            <FormControl required>
                                <TextField
                                    label='Total price you charge (including cleaning fee)'
                                    sx={{ mb: 2 }}
                                    InputProps={{
                                        startAdornment: "$",
                                        inputProps: { min: 0 },
                                        endAdornment: <InputAdornment position="end">/per day</InputAdornment>
                                    }}
                                    type="number"
                                    fullWidth
                                    error={!!errors['priceForComparison']}
                                    helperText={errors['priceForComparison'] ? errors['priceForComparison'].message : ''}
                                    value={value}
                                    name={name}
                                    onChange={
                                        async (event) => {
                                            const newMonitor: MonitorUpdateViewResource = await recalculateMonitor(getValues(), name, event.target.value)
                                            updateMonitorDataOnForm(newMonitor);
                                            validateFields();
                                        }
                                    }
                                />
                            </FormControl>
                        )}
                    />

                    <Controller
                        control={control}
                        name="enabled"
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState: { dirtyFields },
                        }) => (
                            <FormControl sx={{ my: 0 }} component="fieldset" variant="standard">
                                <FormGroup>
                                    <FormLabel>Enabled</FormLabel>
                                    <div>
                                        <Checkbox
                                            name={name}
                                            checked={value}
                                            onChange={
                                                async (event) => {
                                                    const newMonitor: MonitorUpdateViewResource = await recalculateMonitor(getValues(), name, event.target.checked);
                                                    updateMonitorDataOnForm(newMonitor);
                                                    validateFields();
                                                }
                                            }
                                        />
                                    </div>
                                </FormGroup>
                            </FormControl>
                        )}
                    />



                    <FormControl>
                        <Stack direction="row" spacing={2}>
                            <Button type="submit" variant="contained" color="primary">Update</Button>
                            <Button type="button" variant="contained" color="primary" onClick={onBackHandler}>Back</Button>
                        </Stack>
                    </FormControl>
                </Box>
            </>
        )

    }

    return render()
}