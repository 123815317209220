import { InstructionCard } from './InstructionCard';
import { Grid } from '@mui/material';
import { Box } from '@mui/material';
import { Stack } from '@mui/material';
import { Button } from '@mui/material';
import { Container } from '@mui/material';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useState } from 'react';
import { createMonitorCalendarSimple, createMonitorCreateFromMonitorCreateView } from 'domain/monitor';
import { MonitorCreateSimple } from 'features/monitor/ui/MonitorCreateSimple';
import { useCreatePushNotificationSubscriptionMutation } from 'services/pushNotificationSubscription';
import { useDeletePushNotificationSubscriptionMutation } from 'services/pushNotificationSubscription';
import { useCreateMonitorMutation } from 'services/monitor';
import { useNavigate } from 'react-router-dom';
import type { Monitor, MonitorCreateResource, MonitorCreateViewResource } from 'shared/types';

export const Home = (): JSX.Element => {
    let navigate = useNavigate();
    const [createPushNotificationSubscription] = useCreatePushNotificationSubscriptionMutation();
    const [deletePushNotificationSubscriptionMutation] = useDeletePushNotificationSubscriptionMutation();

    const deletePushNotificationSubscriptionHandler = async (subscription: any) => {
        const endpoint = subscription.endpoint;
        const subscriptionSimpleObject = JSON.parse(JSON.stringify(subscription));

        await deletePushNotificationSubscriptionMutation({
            endpoint: endpoint,
            p256dh: subscriptionSimpleObject.keys.p256dh,
            auth: subscriptionSimpleObject.keys.auth,
        })
    }

    const createPushNotificationSubscriptionHandler = async (subscription: any) => {
        const endpoint = subscription.endpoint;
        const subscriptionSimpleObject = JSON.parse(JSON.stringify(subscription));

        await createPushNotificationSubscription({
            endpoint: endpoint,
            p256dh: subscriptionSimpleObject.keys.p256dh,
            auth: subscriptionSimpleObject.keys.auth,
        })
    }

    const urlB64ToUint8Array = (base64String: string) => {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
            .replace(/\-/g, '+')
            .replace(/_/g, '/');
        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);
        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    };

    const subscribeToPushNotifications = async () => {
        const result = await Notification.requestPermission();

        if (result === 'denied') {
            return;
        }

        const registration = await navigator.serviceWorker.getRegistration();

        if (registration === undefined) {
            return;
        }

        const subscribed = await registration.pushManager.getSubscription();

        if (subscribed) {
            //
            await createPushNotificationSubscriptionHandler(subscribed);
            //
            return;
        }

        const subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlB64ToUint8Array(`BHeQ1k-h3-p2egmUGJgUhVUey5Zp9BwwfPoR9nL0qzmHk6yH_8aHMgosGcQ7Ly-R1AxEDu5lLNcT8f_k83ldwcI`)
        });

        await createPushNotificationSubscriptionHandler(subscription);
    }

    const unSubscribeToPushNotifications = async () => {
        const registration = await navigator.serviceWorker.getRegistration();

        if (registration === undefined) {
            return;
        }

        const subscription = await registration.pushManager.getSubscription();
        deletePushNotificationSubscriptionHandler(subscription)
    }


    let initialMonitor: MonitorCreateViewResource = createMonitorCalendarSimple();
    const [monitor] = useState<MonitorCreateViewResource>(initialMonitor);
    const [createMonitor] = useCreateMonitorMutation();

    const handleCompetitorCalendarCreateSimpleSubmit = async ({ event, value }: any) => {

        const monitor: MonitorCreateResource = createMonitorCreateFromMonitorCreateView(value);
        const createMonitorResult: any = await createMonitor({
            monitor: monitor
        })
        navigate(`/spy/${createMonitorResult?.data?.id}/monitorrun`);
    }

    return (
        <>
            <Container
                sx={{
                    width: {
                        md: '50%'
                    },
                    margin: {
                        md: '5% 0 0 25%'
                    },
                }}
            >
                <MonitorCreateSimple
                    defaultValue={monitor}
                    onSubmit={handleCompetitorCalendarCreateSimpleSubmit}
                ></MonitorCreateSimple>
            </Container>
        </>
    )
}