import { BED_COUNTS } from '../constants'

export function findBedCountByValue(value: number[]) {
    const foundValue = BED_COUNTS.find((bedCount) => {
        if (bedCount.value.length !== value.length) {
            return false
        }

        return bedCount.value.every((bc, index) => {
            return bc === value[index]
        })

    })

    return foundValue
}