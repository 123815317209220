import { useState } from 'react';
import { useEffect } from 'react';
import { BedSelectItem, BedroomSelectItem, Monitor, PetSelectItem, Platform } from 'shared/types';
import { periodLabel } from 'domain/monitor';
import { MonitorViewModel } from 'shared/types';
import { useReadPlatformsQuery } from 'services/platform';
import { DATE_PERIODS } from 'shared/constants';
import { BEDROOM_COUNTS } from 'shared/constants';
import { BED_COUNTS } from 'shared/constants';
import { PET_COUNTS } from 'shared/constants';
import { findBedroomCountByLabel } from '../../../shared/lib/findBedroomCountByLabel';
import { findBedroomCountByValue } from '../../../shared/lib/findBedroomCountByValue';

type MonitorListToMonitorViewModelListProps = {
    monitorList: Monitor[],
    onMonitorViewModelListChanged?: Function,
};

export const MonitorListToMonitorViewModelList = (props: MonitorListToMonitorViewModelListProps) => {
    const { data: readPlatformsQueryData, isSuccess: readPlatformsQueryIsSuccess } = useReadPlatformsQuery();
    const [ monitorViewModelList, setMonitorViewModelList ] = useState<MonitorViewModel[]>([])
    props = {
        ...{
            monitorList: [],
        },
        ...props
    }

    const _getBedroomsLabel = (monitor: Monitor): string => {
        const bedroomCount = findBedroomCountByValue(monitor.bedrooms)

        if (bedroomCount === undefined) {
            return BEDROOM_COUNTS[0].label;
        }

        return bedroomCount.label;
    }

    const _getBedsLabel = (monitor: Monitor): string => {
        const bedsCount = findBedroomCountByValue(monitor.beds)
        if (bedsCount === undefined) {
            return BED_COUNTS[0].label;
        }

        return bedsCount.label;
    }

    const _getPetsLabel = (monitor: Monitor): string => {
        const petCount = PET_COUNTS.find((data: PetSelectItem) => data.value === monitor.pets);
        if (petCount === undefined) {
            return '';
        }

        return petCount.label;
    }

    const _getPlatform = (platforms: Platform[], monitor: Monitor): Platform => {
        const platform: Platform = readPlatformsQueryData.list.find((data: any) => data.id === monitor.platformId);

        return platform;
    }


    useEffect(() => {
        if (readPlatformsQueryIsSuccess === true
            && props.monitorList !== undefined) {
                const monitorViewModelsTempList: MonitorViewModel[] = [];
            if (props.monitorList !== undefined) {

                props.monitorList.forEach((monitor) => {
                        const platform = _getPlatform(readPlatformsQueryData.list, monitor);
                        const period = periodLabel(monitor);
                        const bedroomsLabel = _getBedroomsLabel(monitor);
                        const bedsLabel = _getBedsLabel(monitor);
                        const petsLabel = _getPetsLabel(monitor);

                        const monitorViewModel: MonitorViewModel = {
                            ...monitor,
                            platform: platform,
                            period: period,
                            bedroomsLabel: bedroomsLabel,
                            bedsLabel: bedsLabel,
                            petsLabel: petsLabel
                        };
                        monitorViewModelsTempList.push(monitorViewModel);
                    });
                }

                setMonitorViewModelList(monitorViewModelsTempList);
            }
    }, [readPlatformsQueryIsSuccess, props.monitorList]);

    useEffect(() => {
        if (typeof props.onMonitorViewModelListChanged === "function") {
            props.onMonitorViewModelListChanged({
                value: monitorViewModelList
            });
        }
    }, [monitorViewModelList]);

    return null;
};