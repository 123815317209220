import { BEDROOM_COUNTS } from '../constants'

export function findBedroomCountByValue(value: number[]) {
    const foundValue = BEDROOM_COUNTS.find((bedroomCount) => {
        if (bedroomCount.value.length !== value.length) {
            return false
        }

        return bedroomCount.value.every((bc, index) => {
            return bc === value[index]
        })

    })

    return foundValue
}